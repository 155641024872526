<template>
  <section class="portfolio">
    <div class="page-header">
      <h3 class="page-title">
        Mes sites
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Sites et applis</a></li>
          <li class="breadcrumb-item active" aria-current="page">Sites</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- bouton ajouter -->
            <div class="row">
              <div class="col-12 mb-4 text-right">
                  <button type="button" class="btn  btn-success" @click="addSite()">Créer un nouveau site</button>
              </div>
            </div>
            <!-- contenu de la page sites et applications -->
            <div class="row">
              <div class="col-md-12 table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Nom du site</th>
                      <th>Nombre de pages</th>
                      <th>Administrateurs</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="site in sites" :key="site.id">
                      <td class="pointer" @click="gotoApiServices(site)">{{site.name}}</td>
                      <td>{{site.pages.length}}</td>
                      <td>{{site.owner.map(owner => owner.pseudo).join(", ")}}</td>
                      <td class="">
                        <i class="fa fa-trash pointer" @click="showAlertDelete(site.ulid)"></i>
                        <i class="fa fa-edit pointer mx-4" @click="editSite(site.ulid)"></i>
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios = require('axios').default
export default {
  name: 'website',
  data () {
    return {
      subMenu: 'sitesApp',
      sites:[]
    }
  },
  methods: {
    getWebsites () {
      axios.get('sites')
      .then ( resWebsite=> {
        this.sites = resWebsite.data.result
        console.log(this.sites.map(site => site.name));
      })
      .catch (err => console.log(err))
    },
    addSite(){
      this.$router.push({
        name:"addSite"
      })
    },
    showpages(website) {
      this.$router.push(
        {
          name: 'page',
          params: {
            site: website.name,
            ulidsite: website.ulid
          }
        }
      )
    },
    showAlertDelete(ulid){
      this.$swal({
        title: 'Êtes-vous sûr?',
        text: 'Voulez-vous supprimer ce site?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, Supprimer',
        cancelButtonText: 'Annuler',
      })
      .then((result) => {
        if (result.value) {
        this.isload =true
          this.deleteSite(ulid)
        }
      })
    },
    deleteSite(ulid){
      axios.delete(`sites/${ulid}`)
      .then(resDeleteSite => {
        console.table({resDeleteSite});
      this.$swal(
            'Supprimé!',
            'Le site a bien été supprimé',
            'success'
          )
        this.getWebsites()
      })
      .catch(errDeleteSite => {
        console.log(errDeleteSite);
      })
    },
    editSite(ulid) {
      this.$router.push({
        name:"editSite",
        params:{
          ulid:ulid
        }
      })
    },
    gotoApiServices(site){
      this.$router.push({
        name:"api-services-list",
        params:{
          ulidsite:site.ulid,
          site:site.name
        }
      })
    }
  },
  created() {
    this.getWebsites()
  },
  mounted(){
  }
}
</script>